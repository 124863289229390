import React, { useState } from 'react';
import Slider from 'react-slick';
import dogFace from 'assets/images/dog-face.png';
import critterCuddlerSmall from 'assets/images/critter-cuddler-small.png';
import critterCuddlerSmallWebp from 'assets/images/critter-cuddler-small.png?as=webp';
import critterCuddlerCrop from 'assets/images/critter-cuddler-crop.png';
import critterCuddlerCropWebp from 'assets/images/critter-cuddler-crop.png?as=webp';
import construction from 'assets/images/construction.png';
import homeWreckerSmall from 'assets/images/home-wrecker-small.png';
import homeWreckerSmallWebp from 'assets/images/home-wrecker-small.png?as=webp';
import homeWreckerCropWebp from 'assets/images/home-wrecker-crop1.webp';
import homeWreckerCropPng from 'assets/images/home-wrecker-crop1.png';
import sneezingFace from 'assets/images/sneezing-face.png';
import bigSneezeSmall from 'assets/images/big-sneeze-small.png';
import bigSneezeSmallWebp from 'assets/images/big-sneeze-small.png?as=webp';
import bigSneezeCrop from 'assets/images/big-sneeze-crop.png';
import bigSneezeCropWebp from 'assets/images/big-sneeze-crop.png';
import baby from 'assets/images/baby.png';
import rookieParentSmall from 'assets/images/rookie-parent-small.png';
import rookieParentSmallWebp from 'assets/images/rookie-parent-small.png?as=webp';
import rookieParentCrop from 'assets/images/rookie-parent-crop.png';
import rookieParentCropWebp from 'assets/images/rookie-parent-crop.png?as=webp';
import leafFlutteringInWind from 'assets/images/leaf-fluttering-in-wind.png';
import basicBreatherSmall from 'assets/images/basic-breather-small.png';
import basicBreatherSmallWebp from 'assets/images/basic-breather-small.png?as=webp';
import basicBreatherCrop from 'assets/images/basic-breather-crop.png';
import basicBreatherCropWebp from 'assets/images/basic-breather-crop.png?as=webp';
import woman from 'assets/images/woman.png';
import mamaSmall from 'assets/images/mama-small.png';
import mamaSmallWebp from 'assets/images/mama-small.png?as=webp';
import mamaToBeCrop from 'assets/images/mama-to-be-crop.png';
import mamaToBeCropWebp from 'assets/images/mama-to-be-crop.png?as=webp';
import explodingHead from 'assets/images/exploding-head.png';
import overreactorSmall from 'assets/images/overreactor-small.png';
import overreactorSmallWebp from 'assets/images/overreactor-small.png?as=webp';
import overreactorCrop from 'assets/images/overreactor-crop.png';
import overreactorCropWebp from 'assets/images/overreactor-crop.png?as=webp';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

const LazyImg = loadable(() => import('../common/LazyImg'));
const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param setSlider
 * @param contentSliderSettings
 * @returns {JSX.Element}
 * @constructor
 */
const HighlightedBlockCarousel = ({ setSlider, contentSliderSettings }) => (
  <Slider
    className='highlighted-block__carousel'
    ref={slider => setSlider(slider)}
    {...contentSliderSettings}
  >
    <div className='highlighted-block__item highlighted-block__item--critter-cuddler'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#F42D53' }}>
            The
            {' '}
            <LazyImg
              src={dogFace}
              alt='dog face'
            />
            {' '}
            <br />
            Critter Cuddler.
          </h3>
          <h4 className='content-card__subtitle'>Clearing the air for fur families.</h4>
          <div className='content-card__description'>
            <p>
              Pet parents, rejoice. This HEPA + heavy-duty carbon filter removes pet dander and
              odors to keep your
              place free of animal funk and allergens.
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source
                    srcSet={critterCuddlerSmallWebp}
                    type='image/webp'
                  />
                  <source
                    srcSet={critterCuddlerSmall}
                    type='image/png'
                  />
                  <img
                    src={critterCuddlerSmall}
                    alt='critter cuddler filter'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/critter-cuddler'
            className='btn btn--small'
            style={{ backgroundColor: '#F42D53' }}
          >
            Learn
            More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--1'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source
                srcSet={critterCuddlerCropWebp}
                type='image/webp'
              />
              <source
                srcSet={critterCuddlerCrop}
                type='image/png'
              />
              <img
                src={critterCuddlerCrop}
                alt='critter cuddler filter'
              />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--home-wrecker'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#F27E0F' }}>
            The
            {' '}
            <LazyImg
              src={construction}
              alt='construction'
            />
            {' '}
            <br />
            Home Wrecker.
          </h3>
          <h4 className='content-card__subtitle'>Protecting you from that “new home” smell</h4>
          <div className='content-card__description'>
            <p>
              Taking a paintbrush (or sledgehammer) to your place? This filter makes sure the
              chemicals and odors
              from new paint, carpet, or furniture don’t linger.
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source
                    srcSet={homeWreckerSmallWebp}
                    type='image/webp'
                  />
                  <source
                    srcSet={homeWreckerSmall}
                    type='image/png'
                  />
                  <img
                    src={homeWreckerSmall}
                    alt='home wrecker filter'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/home-wrecker'
            className='btn btn--small'
            style={{ backgroundColor: '#F27E0F' }}
          >
            Learn
            More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--2'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source
                srcSet={homeWreckerCropWebp}
                type='image/webp'
              />
              <source
                srcSet={homeWreckerCropPng}
                type='image/png'
              />
              <img
                alt='home wrecker filter'
                src={homeWreckerCropPng}
              />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--big-sneeze'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#FFC140' }}>
            The
            {' '}
            <LazyImg
              src={sneezingFace}
              alt='sneezing face'
            />
            {' '}
            <br />
            Big Sneeze.
          </h3>
          <h4 className='content-card__subtitle'>Helping you fight back against allergens</h4>
          <div className='content-card__description'>
            <p>
              Consider yourself saved from indoor/outdoor allergies with this filter designed
              specifically to
              eliminate the particles that make you a-a-achoo.
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={bigSneezeSmallWebp} type='image/webp' />
                  <source srcSet={bigSneezeSmall} type='image/png' />
                  <img
                    src={bigSneezeSmall}
                    alt='big sneeze filter'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/big-sneeze'
            className='btn btn--small'
            style={{ backgroundColor: '#FFC140' }}
          >
            Learn More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--3'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source srcSet={bigSneezeCropWebp} type='image/webp' />
              <source srcSet={bigSneezeCrop} type='image/png' />
              <img src={bigSneezeCrop} alt='big sneeze slide filter' />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--rookie-parent'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#8852F6' }}>
            The
            {' '}
            <LazyImg src={baby} alt='baby' />
            {' '}
            <br />
            Rookie Parent
          </h3>
          <h4 className='content-card__subtitle'>
            Making the air clean and safe for your
            littles.
          </h4>
          <div className='content-card__description'>
            <p>
              This filter is the perfect protection for young families with sensitive or developing
              immune systems
              (and stinky diapers).
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={rookieParentSmallWebp} type='image/webp' />
                  <source srcSet={rookieParentSmall} type='image/png' />
                  <img
                    src={rookieParentSmall}
                    alt='rookie parent small'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/rookie-parent'
            className='btn btn--small'
            style={{ backgroundColor: '#8852F6' }}
          >
            Learn More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--4'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source srcSet={rookieParentCropWebp} type='image/webp' />
              <source srcSet={rookieParentCrop} type='image/png' />
              <img
                src={rookieParentCrop}
                alt='rookie parent filter'
              />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--basic-breather'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#3E4649' }}>
            The
            {' '}
            <LazyImg
              src={leafFlutteringInWind}
              alt='leaf fluttering in wind'
            />
            {' '}
            <br />
            Basic
            Breather.
          </h3>
          <h4 className='content-card__subtitle'>For the everyday lover of clean air</h4>
          <div className='content-card__description'>
            <p>
              This brilliant basic is designed for those who don’t have any specific needs, other
              than the desire
              for clean, fresh air.
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={basicBreatherSmallWebp} type='image/webp' />
                  <source srcSet={basicBreatherSmall} type='image/png' />
                  <img
                    src={basicBreatherSmall}
                    alt='basic breather small'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/basic-breather'
            className='btn btn--small'
            style={{ backgroundColor: '#3E4649' }}
          >
            Learn
            More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--5'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source srcSet={basicBreatherCropWebp} type='image/webp' />
              <source srcSet={basicBreatherCrop} type='image/png' />
              <img src={basicBreatherCrop} alt='basic breather filter' />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--mama'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#1CE4D5 ' }}>
            The
            {' '}

            <LazyImg
              src={woman}
              alt='woman'
            />

            {' '}
            <br />
            Mama-to-Be
          </h3>
          <h4 className='content-card__subtitle'>Extra protection when you’re breathing for two</h4>
          <div className='content-card__description'>
            <p>
              Expect cleaner air when you're breathing for two with 99.995% efficiency filtration
              (plus elimination
              of all the odors you can’t stand the smell of right now).
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={mamaSmallWebp} type='image/webp' />
                  <source srcSet={mamaSmall} type='image/png' />
                  <img
                    src={mamaSmall}
                    alt='mama-small'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/mama-to-be'
            className='btn btn--small'
            style={{ backgroundColor: '#1CE4D5' }}
          >
            Learn More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--6'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source srcSet={mamaToBeCropWebp} type='image/webp' />
              <source srcSet={mamaToBeCrop} type='image/png' />
              <img
                src={mamaToBeCrop}
                alt='mama to be'
              />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
    <div className='highlighted-block__item highlighted-block__item--overreactor'>
      <div className='highlighted-block__item-card content-card'>
        <div className='content-card__detail'>
          <h3 className='content-card__title' style={{ color: '#0A56EA' }}>
            The
            {' '}
            <LazyImg
              src={explodingHead}
              alt='exploding-head'
            />
            {' '}
            <br />
            Overreactor
          </h3>
          <h4 className='content-card__subtitle'>Protection from ALL the things</h4>
          <div className='content-card__description'>
            <p>
              The mother of all filters, this one combats anything and everything that could be
              lurking in your
              air.
            </p>
          </div>
        </div>
        <div className='content-card__list-wrap'>
          <figure className='content-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={overreactorSmallWebp} type='image/webp' />
                  <source srcSet={overreactorSmall} type='image/png' />
                  <img
                    src={overreactorSmall}
                    alt='overreactor'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <Link
            to='/filters/overreactor'
            className='btn btn--small'
            style={{ backgroundColor: '#0A56EA' }}
          >
            Learn More
          </Link>
        </div>
      </div>
      <figure className='highlighted-block__item-image highlighted-block__item-image--7'>
        <LazyLoad>
          <FadeIn>
            <picture>
              <source srcSet={overreactorCropWebp} type='image/webp' />
              <source srcSet={overreactorCrop} type='image/png' />
              <img
                src={overreactorCrop}
                alt='overreactor'
              />
            </picture>
          </FadeIn>
        </LazyLoad>
      </figure>
    </div>
  </Slider>
);

/**
 * @param setSlider
 * @param backgroundSliderSettings
 * @returns {JSX.Element}
 * @constructor
 */
const HighlightedBlockBackgroundCarousel = ({ setSlider, backgroundSliderSettings }) => (
  <Slider
    className='highlighted-block__background-carousel'
    ref={slider => setSlider(slider)}
    {...backgroundSliderSettings}
  >
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-1'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-2'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-3'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-4'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-5'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-6'
    />
    <div
      className='highlighted-block__background-slide highlighted-block__background-slide--bg-7'
    />
  </Slider>
);

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HighlightedBlock = () => {
  const [ slider1, setSlider1 ] = useState(null);
  const [ slider2, setSlider2 ] = useState(null);
  const [ currentSlide, setCurrentSlide ] = useState(0);

  const goToFirst = () => {
    slider1?.slickGoTo(0);
  };
  const afterChangeHandle = (current, next) => {
    if (next === current) {
      setTimeout(() => {
        goToFirst();
      }, 8000);
    }
  };
  const contentSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: false,
    fade: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: 'ease-in-out',
    asNavFor: slider2,
    beforeChange: (_oldIndex, newIndex) => { afterChangeHandle(6, newIndex); setCurrentSlide(newIndex); },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          infinite: true,
        },
      }],
  };

  const backgroundSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: false,
    fade: true,
    autoplay: false,
    speed: 500,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: 'ease-in-out',
    asNavFor: slider1,
    responsive: [{ breakpoint: 767, settings: { infinite: true }}],
  };

  return (
    <section className='highlighted-block'>
      <div className='highlighted-block__inner' style={{ backgroundColor: '#F42D53' }}>
        <div className='highlighted-block__intro'>
          <h2 className='highlighted-block__intro-title'>
            There’s a filter for you and your
            peeps.
          </h2>
          <div className='highlighted-block__intro-content'>
            <p>
              Everybody has different challenges with the air they breathe. Whether you and yours
              are up against
              allergies, pet odors, urban smog, or home renovation dust and chemicals—Mila has you
              covered.
            </p>
          </div>
        </div>
        <HighlightedBlockCarousel
          setSlider={setSlider1}
          contentSliderSettings={contentSliderSettings}
        />
        <HighlightedBlockBackgroundCarousel
          setSlider={setSlider2}
          backgroundSliderSettings={backgroundSliderSettings}
        />
        <div className='highlighted-block__slider-arrow'>
          <div className='highlighted-block__arrow-inner'>
            <button
              type='button'
              className={`highlighted-block__prev-button slick-arrow ${currentSlide === 0 ? 'slick-disabled' : ''}`}
              onClick={() => slider1?.slickPrev()}
              aria-label='Previous Slide'
            >
              <span className='screen-reader-text'>Previous Slide</span>
              <svg className='icon icon-circle-left-arrow'>
                <use xlinkHref='#icon-circle-left-arrow' />
              </svg>
            </button>
            <button
              type='button'
              className={`highlighted-block__next-button slick-arrow ${currentSlide === 6 ? 'slick-disabled' : ''}`}
              onClick={() => slider1?.slickNext()}
              aria-label='Next Slide'
            >
              <span className='screen-reader-text'>Next Slide</span>
              <svg className='icon icon-circle-right-arrow'>
                <use xlinkHref='#icon-circle-right-arrow' />
              </svg>
            </button>
          </div>
          <div className='highlighted-block__arrow-space' />
        </div>
      </div>
    </section>
  );
};

export default HighlightedBlock;
